import { render, staticRenderFns } from "./CarouselButton.vue?vue&type=template&id=5abf0f0f&scoped=true&"
import script from "./CarouselButton.vue?vue&type=script&lang=ts&"
export * from "./CarouselButton.vue?vue&type=script&lang=ts&"
import style0 from "./CarouselButton.vue?vue&type=style&index=0&id=5abf0f0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5abf0f0f",
  null
  
)

export default component.exports