/* istanbul ignore file */
import { ISearchParams } from '@unified-commerce/gpc-vue-storefront-search-io';

import getVehicleParams from '~/helpers/vehicle/getVehicleParams';

export const getVehicleSelectionsData =
  (searchFunctions: any) =>
  ({ selectionType, filters = {} }: any): ISearchParams => {
    return searchFunctions[selectionType]({
      ...getVehicleParams(),
      vehicleFilters: filters,
    });
  };
