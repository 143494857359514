/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* istanbul ignore file */
import { computed, reactive } from '@nuxtjs/composition-api';

import { useUiHelpers } from '~/composables';

const { lockPageScrollOnMobileOnly, unlockPageScrollOnMobileOnly } = useUiHelpers();

const state = reactive({
  isCartSidebarOpen: false,
  isVehicleSelectionSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isGarageSelectionSidebarOpen: false,
  isGarageModalOpen: false,
  isAccountModalOpen: false,
  isSignup: false,
  isLogoutModalOpen: false,
  isCategoryGridView: true,
  isFilterSidebarOpen: false,
  isNavigationSidebarOpen: false,
  vehicleAskus: null as { askus?: string[]; kTypeNr?: string } | null,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUiState = () => {
  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = () => {
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
    if (state.isCartSidebarOpen) {
      lockPageScrollOnMobileOnly();
    } else {
      unlockPageScrollOnMobileOnly();
    }
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const isNavigationSidebarOpen = computed(() => state.isNavigationSidebarOpen);
  const toggleNavigationSidebar = () => {
    state.isNavigationSidebarOpen = !state.isNavigationSidebarOpen;
  };

  const isGarageModalOpen = computed(() => state.isGarageModalOpen);
  const setGarageModalOpen = (isOpen: boolean) => {
    state.isGarageModalOpen = isOpen;
  };

  const isAccountModalOpen = computed(() => state.isAccountModalOpen);

  const showAccountModal = () => {
    state.isAccountModalOpen = true;
  };
  const toggleAccountModal = () => {
    state.isAccountModalOpen = !state.isAccountModalOpen;
  };

  const isSignup = computed(() => state.isSignup);

  const setIsSignup = (boolean: boolean) => {
    state.isSignup = boolean;
  };

  const isLogoutModalOpen = computed(() => state.isLogoutModalOpen);
  const showLogoutModal = () => {
    state.isLogoutModalOpen = true;
  };
  const toggleLogoutModal = () => {
    state.isLogoutModalOpen = !state.isLogoutModalOpen;
  };
  const closeLogoutModal = () => {
    state.isLogoutModalOpen = false;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);
  const toggleCategoryGridView = () => {
    state.isCategoryGridView = !state.isCategoryGridView;
  };

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const vehicleAskus = computed(() => state.vehicleAskus);
  const setVehicleAskus = (askus: string[], kTypeNr: string) => {
    state.vehicleAskus = { askus, kTypeNr };
  };
  const clearVehicleAskus = () => {
    state.vehicleAskus = null;
  };

  return {
    vehicleAskus,
    setVehicleAskus,
    clearVehicleAskus,
    isCartSidebarOpen,
    isWishlistSidebarOpen,
    isAccountModalOpen,
    isGarageModalOpen,
    isSignup,
    setIsSignup,
    isLogoutModalOpen,
    isCategoryGridView,
    isFilterSidebarOpen,
    isNavigationSidebarOpen,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleAccountModal,
    showAccountModal,
    toggleLogoutModal,
    closeLogoutModal,
    showLogoutModal,
    toggleCategoryGridView,
    toggleFilterSidebar,
    toggleNavigationSidebar,
    setGarageModalOpen,
  };
};

export default useUiState;
