const sanitiseProductId = (id?: string): string => {
  if (!id) {
    return '';
  }

  if (id.startsWith('gid://shopify')) {
    const gidSplit = id.split('/');
    const shopifyId = gidSplit[gidSplit.length - 1];

    return shopifyId?.split('?')[0] ?? '';
  }

  return id;
};

export { sanitiseProductId };
