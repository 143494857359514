/* istanbul ignore file */
import { Collection, ISearchParams } from '@unified-commerce/gpc-vue-storefront-search-io';

const getVehicleParams = (): ISearchParams => {
  return {
    resultsPerPage: 1,
    collection: Collection.VEHICLES,
    pipeline: {},
  };
};

export default getVehicleParams;
