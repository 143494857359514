import StoryblokVue from 'storyblok-vue';
import Vue from 'vue';

import { integrationPlugin } from '@unified-commerce/vue-storefront-integration-plugin';

Vue.use(StoryblokVue);

const moduleOptions = JSON.parse('{"jsBridge":"https://app.storyblok.com/f/storyblok-v2-latest.js"}');

export default integrationPlugin(({ app, integration }) => {
  integration.configure('sb', {
    ...moduleOptions,
    app,
  });
});
