export interface IBrandContent {
  content: {
    content: [
      {
        brand: {
          content: {
            logo: {
              filename: string;
              alt: string;
            };
            name: string;
            description: {
              content: unknown[];
            };
            product_category?: {
              shopify_category?: {
                items?: {
                  name: string;
                }[];
              };
            }[];
          };
        };
      },
    ];
  };
  _meta: {
    slug: string;
    full_slug: string;
  };
}

export const mapBrand = (brand: IBrandContent) => {
  const brandContent = brand?.content?.content[0]?.brand?.content;
  const brandMeta = brand?._meta;

  return {
    name: brandContent?.name,
    logo: brandContent?.logo,
    description: brandContent?.description,
    slug: brandMeta?.slug,
  };
};

const VENDOR_CATEGORY_PREFIX = 'AUTOGEN::Brands>';

export const mapVendorsToBrandSlugs = (brands: IBrandContent[] = []) =>
  brands.reduce((map: Record<string, string>, brand: IBrandContent) => {
    const shopifyCategory =
      brand.content?.content?.[0]?.brand?.content?.product_category?.[0]?.shopify_category
        ?.items?.[0]?.name || '';

    if (!shopifyCategory.startsWith(VENDOR_CATEGORY_PREFIX)) {
      return map;
    }

    const vendor = shopifyCategory.replace(VENDOR_CATEGORY_PREFIX, '');
    const slug = brand._meta?.full_slug;

    if (vendor && slug) {
      map[vendor] = `/${slug}`;
    }

    return map;
  }, {});
