export type VideoId = string;
export type EmbeddedUrl = string;
export type EmbeddedAutoplayURL = string;

const YOUTUBE_AUTOPLAY_QUERY_PARAM = 'autoplay=1';
const YOUTUBE_EMBED_BASE_URL = 'https://www.youtube.com/embed';

export const buildAutoPlayURL = (ID: VideoId): EmbeddedAutoplayURL => {
  return `${YOUTUBE_EMBED_BASE_URL}/${ID}?${YOUTUBE_AUTOPLAY_QUERY_PARAM}`;
};

export const buildURL = (ID: VideoId): EmbeddedUrl => {
  return `${YOUTUBE_EMBED_BASE_URL}/${ID}`;
};

export const appendAutoplayToURL = (youTubeEmbedURL: EmbeddedUrl): EmbeddedAutoplayURL => {
  return `${youTubeEmbedURL}?${YOUTUBE_AUTOPLAY_QUERY_PARAM}`;
};

/*
 * Lazytube doesnt define types and to call its methods we need to let TS know what they are
 * not sure where this should belong
 */
export interface LazyTubeInterface extends Vue {
  playVideo: () => void;
  stopVideo: () => void;
  pauseVideo: () => void;
  resetView: () => void;
  handleClick: () => void;
}
