<template>
  <BaseModal v-e2e="'login-modal'" :visible="isAccountModalOpen" class="modal" @close="closeModal">
    <template #modal-bar>
      <BaseBar
        class="sf-modal__bar smartphone-only"
        :close="true"
        :title="$t(barTitle)"
        @click:close="closeModal"
      />
    </template>
    <transition name="sf-fade" mode="out-in">
      <div v-if="isLogin">
        <LoginForm @success="toggleAccountModal" />
        <div class="bottom">
          <p class="bottom__paragraph">{{ $t('No account') }}</p>
          <BaseButton class="sf-button--text" @click="setIsLoginValue(false)">
            {{ $t('Register today') }}
          </BaseButton>
        </div>
      </div>
      <div v-else>
        <SignupForm
          @register-success="updateSignUpFormContent"
          @internal-login-success="closeModal"
        />
        <div v-if="!isSignupSuccess" class="action">
          {{ $t('or') }}
          <BaseButton class="sf-button--text" @click="setIsLoginValue(true)">
            {{ $t('login in to your account') }}
          </BaseButton>
        </div>
      </div>
    </transition>
  </BaseModal>
</template>
<script>
import { computed, reactive, ref } from '@nuxtjs/composition-api';
import { BaseBar, BaseButton, BaseModal } from '@unified-commerce/gpc-ui-library';

import { useUiState } from '~/composables';

import LoginForm from './LoginForm.vue';
import SignupForm from './SignupForm.vue';

export default {
  name: 'AccountModal',
  components: {
    BaseModal,
    SignupForm,
    BaseButton,
    BaseBar,
    LoginForm,
  },
  props: {
    isSignup: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { isAccountModalOpen, toggleAccountModal } = useUiState();
    const form = ref({});
    const isLogin = ref(!props.isSignup);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const createAccount = ref(false);
    const rememberMe = ref(false);
    const forgotPasswordError = ref(false);
    const forgotPasswordLoading = ref(false);
    const isSignupSuccess = ref(false);
    const error = reactive({
      login: null,
      register: null,
    });

    const barTitle = computed(() => {
      if (isLogin.value) {
        return 'Sign in';
      } else if (isForgotten.value || isThankYouAfterForgotten.value) {
        return 'Reset Password';
      } else {
        return 'Register';
      }
    });

    const setIsLoginValue = (value) => {
      isLogin.value = value;
    };

    const setIsForgottenValue = (value) => {
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const updateSignUpFormContent = () => {
      isSignupSuccess.value = true;
    };

    const closeModal = () => {
      setIsForgottenValue(false);
      isSignupSuccess.value = false;
      toggleAccountModal();
    };

    return {
      form,
      error,

      isLogin,
      createAccount,
      rememberMe,
      isAccountModalOpen,
      toggleAccountModal,
      isSignupSuccess,
      updateSignUpFormContent,

      setIsLoginValue,
      isForgotten,
      setIsForgottenValue,
      forgotPasswordError,
      forgotPasswordLoading,

      closeModal,
      isThankYouAfterForgotten,
      userEmail,
      barTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal {
  --modal-index: var(--modal-z-index);
  --overlay-z-index: var(--modal-z-index);
}
.form {
  margin-top: var(--spacer-sm);
  &__element {
    margin: 0 0 var(--spacer-xl) 0;
  }
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
  font: var(--font-weight--light) var(--font-size--base) / 1.6 var(--font-family--secondary);
  & > * {
    margin: 0 0 0 var(--spacer-xs);
  }
}
.action {
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
}
.checkbox {
  margin-bottom: var(--spacer-2xl);
}
.bottom {
  text-align: center;
  margin-bottom: var(--spacer-lg);
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight--semibold);
  font-family: var(--font-family--secondary);
  &__paragraph {
    color: var(--c-primary);
    margin: 0 0 var(--spacer-base) 0;
    @include lg-devices-min {
      margin: 0;
    }
  }
}
.thank-you {
  &__paragraph {
    &--bold {
      font-weight: var(--font-weight--semibold);
    }
  }
}
</style>
