var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UCCardSection',{attrs:{"title":_vm.title,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '20%',
    cardFlexGrow: 1,
    headerStyle: {
      textAlign: 'left',
    },
    headerLinkStyle: {
      textDecoration: 'underline',
    },
    groupStyle: {
      overflow: 'visible',
      margin: '0',
    },
  },"tablet":{
    cardMode: 'scroll',
    cardFlexBasis: '20%',
    headerStyle: {
      textAlign: 'left',
    },
    headerLinkStyle: {
      textDecoration: 'underline',
    },
    groupStyle: {
      overflow: 'visible',
      margin: '0',
    },
  },"desktop":{
    showSteppers: false,
    cardMode: 'wrap',
    cardFlexBasis: '20%',
    groupStyle: {
      overflow: 'visible',
      margin: '0',
    },
    headerStyle: {
      textAlign: 'left',
    },
    headerLinkStyle: {
      textDecoration: 'underline',
    },
  },"link":_vm.getUrlLink(_vm.link.cached_url),"link-text":_vm.linkText,"always-show-link-arrow":true}},_vm._l((_vm.videos),function(block,index){return _c('YoutubeVideoTilev2',{key:index,attrs:{"video":block.video}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }