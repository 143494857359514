/* istanbul ignore file */
import useUiHelpers, { SEARCH_CATEGORY_PATH_SEPERATOR } from '~/composables/useUiHelpers';

const { SEARCH_BRAND_PREFIX_TITLE, getCategoryExactTitle } = useUiHelpers();

export const BASE_SEARCH_PATH = '/search';

export interface ISearchResultCategories {
  [category: string]: number;
}

export interface IBrand {
  brandTitle: string;
  productCount: number;
  contentSearchSlug: string;
}

export const getSearchResultBrands = (categories: ISearchResultCategories) => {
  return Object.entries(categories)
    .reduce((searchBrands: IBrand[], [searchCategoryTitle, productCount]) => {
      if (
        searchCategoryTitle.startsWith(SEARCH_BRAND_PREFIX_TITLE) &&
        searchCategoryTitle.includes(SEARCH_CATEGORY_PATH_SEPERATOR)
      ) {
        const brandTitle = getCategoryExactTitle(searchCategoryTitle);
        const titleFirstWord = brandTitle.split(' ')[0].toLowerCase();
        const contentSearchSlug = `brands/*${titleFirstWord}*`;

        searchBrands.push({
          brandTitle,
          productCount,
          contentSearchSlug,
        });
      }

      return searchBrands;
    }, [])
    .sort((a, b) => b.productCount - a.productCount);
};

export const getContentSearchSlugs = (brands: IBrand[]) => {
  return `${brands.map((brand) => brand.contentSearchSlug).join(',')}`;
};
