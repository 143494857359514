import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';

export function useIsMobile() {
  const isMobile = ref(false);

  function resizeHandler() {
    isMobile.value = window.matchMedia('(max-width: 767px)').matches;
  }

  onMounted(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  });
  onUnmounted(() => window.removeEventListener('resize', resizeHandler));

  return { isMobile };
}
