/* eslint-disable max-lines-per-function */
import { computed, reactive } from '@nuxtjs/composition-api';
import Cookies from 'js-cookie';

import { useUiHelpers } from '~/composables';

const UserVehicleSelectionKey = 'userSelections';

export interface IVehicleFilterOption {
  title: string;
  value: string;
}

export interface IVehicleFilter {
  title: string;
  label: string;
  queryField: string;
  isDisabled: boolean;
  options: IVehicleFilterOption[];
}

export interface ISelectedOption {
  title: string;
  value: string;
  queryField: string;
}

export interface IUserSelectedVehicle {
  KtypNr: string;
  name: string;
  model?: string;
  selected?: boolean;
  series?: string;
  shortName?: string;
}

export interface IUserSelectedVehicleOutdated {
  KtypNr: string;
  name: string;
  model?: string;
}

export interface IVehicleRecord {
  KtypNr: string;
  name: string;
  Model: string;
  YearFrom: string;
  YearTo: string;
  Make: string;
  Series: string;
  FuelType: string;
}

export interface IVehicleSearchResult {
  vehicles: IVehicleRecord[];
  type: 'manual' | 'rego';
  rego: null | string;
}

interface IGarageState {
  userSelectedVehicle: IUserSelectedVehicle | null;
  vehiclesInGarage: IUserSelectedVehicle[] | null;
  vehicleAskus: { askus?: string[]; kTypeNr?: string } | null;
  vehicleSearchResult: IVehicleSearchResult;
}

type ViewState = 'closed' | 'garage' | 'manual-search' | 'rego-search' | 'search-result';
interface ISideBarViewState {
  state: ViewState;
  stateHistory: ViewState[];
}

const state = reactive<IGarageState>({
  userSelectedVehicle: null,
  vehiclesInGarage: null,
  vehicleAskus: null,
  vehicleSearchResult: { type: 'manual', rego: null, vehicles: [] },
});

const sideBarViewState = reactive<ISideBarViewState>({
  state: 'closed',
  stateHistory: [],
});

const useMyGarage = () => {
  const { lockPageScrollOnMobileOnly, unlockPageScrollOnMobileOnly } = useUiHelpers();

  const viewState = computed(() => sideBarViewState.state);
  const setViewState = (newState: ViewState) => {
    if (newState === sideBarViewState.state) {
      return;
    }
    if (newState === 'closed') {
      // moving into closed, unlock, clear history
      unlockPageScrollOnMobileOnly();
      sideBarViewState.stateHistory = [];
    } else if (sideBarViewState.state === 'closed') {
      // moving out of closed, lock
      lockPageScrollOnMobileOnly();
    }
    sideBarViewState.stateHistory.push(sideBarViewState.state);
    sideBarViewState.state = newState;
  };

  const rewindViewState = () => {
    const prev = sideBarViewState.stateHistory.pop();
    if (prev) {
      if (prev === 'search-result' || prev === sideBarViewState.state) {
        rewindViewState();
      } else {
        sideBarViewState.state = prev;
      }
    } else {
      setViewState('closed');
    }
  };

  const userSelectedVehicle = computed(() => state.userSelectedVehicle);
  const setUserSelectedVehicle = (selectedVehicle: IUserSelectedVehicle | null) => {
    state.userSelectedVehicle = selectedVehicle;
  };

  const vehicleSearchResult = computed(() => state.vehicleSearchResult);
  const setVehicleSearchResult = (searchResult: IVehicleSearchResult) => {
    state.vehicleSearchResult = searchResult;
  };

  const vehiclesInGarage = computed(() => state.vehiclesInGarage);
  const setVehiclesInGarage = (vehicles: IUserSelectedVehicle[] | null) => {
    state.vehiclesInGarage = vehicles;
  };

  const addVehicle = async (vehicleToAdd: IUserSelectedVehicle) => {
    const currentVehiclesInGarage = await getAndResetVehicles(true);

    const index = currentVehiclesInGarage.findIndex(
      (vehicle) => vehicle.KtypNr === vehicleToAdd.KtypNr,
    );

    vehicleToAdd.selected = true;
    if (index === -1) {
      currentVehiclesInGarage.push(vehicleToAdd);
    } else {
      currentVehiclesInGarage[index].selected = true;
    }

    setVehicleSelectionsInCookie(JSON.stringify(currentVehiclesInGarage));
    setVehiclesSelectionsInLs(JSON.stringify(currentVehiclesInGarage));
    setUserSelectedVehicle(vehicleToAdd);
    setVehiclesInGarage(currentVehiclesInGarage);
  };

  const setVehiclesSelectionsInLs = (selections: string): Promise<unknown> => {
    return Promise.resolve(window.localStorage.setItem(UserVehicleSelectionKey, selections));
  };

  const setVehicleSelectionsInCookie = (selections: string): Promise<unknown> => {
    return Promise.resolve(Cookies.set(UserVehicleSelectionKey, selections, { expires: 365 * 2 }));
  };

  const getAllVehicleSelectionsFromLs = (): Promise<IUserSelectedVehicle[] | null> => {
    const userVehicleSelection = window.localStorage.getItem(UserVehicleSelectionKey);
    return Promise.resolve(userVehicleSelection ? JSON.parse(userVehicleSelection) : null);
  };

  const getVehicleSelectionFromCookie = (): Promise<IUserSelectedVehicle | null> => {
    const userVehicleSelectionsString = Cookies.get(UserVehicleSelectionKey);

    try {
      if (userVehicleSelectionsString) {
        const selectedUserVehicleSelection = getUserSelectedVehicle(userVehicleSelectionsString);
        return Promise.resolve(selectedUserVehicleSelection);
      } else {
        return Promise.resolve(null);
      }
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  const getUserSelectedVehicle = (userSelectedVehicleString: string): IUserSelectedVehicle => {
    const userVehicleSelections: IUserSelectedVehicle[] = JSON.parse(userSelectedVehicleString);
    const selectedUserVehicleSelection = userVehicleSelections.filter(
      (vehicle) => vehicle.selected,
    )[0];

    return selectedUserVehicleSelection;
  };

  const removeVehicleFromGarage = async (vehicleToRemove: IUserSelectedVehicle) => {
    const currentVehicles = await getAndResetVehicles(false);

    const vehiclesRemaining = currentVehicles.filter(
      (vehicle) => vehicle.KtypNr !== vehicleToRemove.KtypNr,
    );
    setVehicleSelectionsInCookie(JSON.stringify(vehiclesRemaining));
    setVehiclesSelectionsInLs(JSON.stringify(vehiclesRemaining));
    if (vehicleToRemove.selected) {
      setUserSelectedVehicle(null);
    }
    setVehiclesInGarage(vehiclesRemaining);
  };

  const resetVehiclesFromGarage = async () => {
    const currentVehiclesInGarage = await getAndResetVehicles(true);
    setVehicleSelectionsInCookie(JSON.stringify(currentVehiclesInGarage));
    setVehiclesSelectionsInLs(JSON.stringify(currentVehiclesInGarage));
    setUserSelectedVehicle(null);
    setVehiclesInGarage(currentVehiclesInGarage);
  };

  const getAndResetVehicles = async (shouldReset: boolean) => {
    const currentVehiclesInGarage: IUserSelectedVehicle[] =
      (await getAllVehicleSelectionsFromLs()) || [];
    if (shouldReset) {
      currentVehiclesInGarage.forEach((vehicleSelection) => {
        vehicleSelection.selected = false;
      });
    }
    return currentVehiclesInGarage;
  };

  const syncSelectionCookieWithLocalstorage = () => {
    const selectionInCookie = Cookies.get(UserVehicleSelectionKey);
    const selectionInLs = window.localStorage.getItem(UserVehicleSelectionKey);
    if (selectionInLs && !selectionInCookie) {
      setVehicleSelectionsInCookie(selectionInLs);
    }
  };

  const getOutdatedSelection = (): IUserSelectedVehicleOutdated | null => {
    const userVehicleSelectionsString = Cookies.get(UserVehicleSelectionKey);
    try {
      if (userVehicleSelectionsString) {
        const selectedUserVehicleSelection = JSON.parse(userVehicleSelectionsString);
        if (!Array.isArray(selectedUserVehicleSelection)) {
          return selectedUserVehicleSelection;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  };

  const initInBrowserState = async () => {
    syncSelectionCookieWithLocalstorage();
    // check first if old user selection
    const outdatedVehicleSelection = getOutdatedSelection();
    if (outdatedVehicleSelection) {
      // port the previous selection and align it with new data shape
      const vehicleToAdd: IUserSelectedVehicle = outdatedVehicleSelection;
      vehicleToAdd.selected = true;
      vehicleToAdd.model = vehicleToAdd.name;
      setVehicleSelectionsInCookie(JSON.stringify([vehicleToAdd]));
      setVehiclesSelectionsInLs(JSON.stringify([vehicleToAdd]));
    }

    const userVehicleSelection = await getVehicleSelectionFromCookie();
    const vehiclesInGarage = await getAllVehicleSelectionsFromLs();
    setVehiclesInGarage(vehiclesInGarage);
    if (userVehicleSelection) {
      setUserSelectedVehicle(userVehicleSelection);
    }
  };

  return {
    initInBrowserState,
    addVehicle,
    resetVehiclesFromGarage,
    removeVehicleFromGarage,
    getUserSelectedVehicle,
    getVehicleSelectionFromCookie,
    setVehicleSelectionsInCookie,
    setVehiclesSelectionsInLs,
    getAllVehicleSelectionsFromLs,
    syncSelectionCookieWithLocalstorage,
    getOutdatedSelection,
    userSelectedVehicle,
    setUserSelectedVehicle,
    vehiclesInGarage,
    setVehiclesInGarage,
    vehicleSearchResult,
    setVehicleSearchResult,
    viewState,
    setViewState,
    rewindViewState,
  };
};

export default useMyGarage;
