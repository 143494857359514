import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3085230d = () => interopDefault(import('../pages/Account.vue' /* webpackChunkName: "pages/Account" */))
const _3a7848e7 = () => interopDefault(import('../pages/Account/AccountDetails.vue' /* webpackChunkName: "pages/Account/AccountDetails" */))
const _99283900 = () => interopDefault(import('../pages/Account/Addresses.vue' /* webpackChunkName: "pages/Account/Addresses" */))
const _597f1121 = () => interopDefault(import('../pages/Account/LogoutModal.vue' /* webpackChunkName: "pages/Account/LogoutModal" */))
const _51d7a6c3 = () => interopDefault(import('../pages/Account/MyOrders.vue' /* webpackChunkName: "pages/Account/MyOrders" */))
const _7662c801 = () => interopDefault(import('../pages/Account/MyOrdersReturnsApp.vue' /* webpackChunkName: "pages/Account/MyOrdersReturnsApp" */))
const _29c9281a = () => interopDefault(import('../pages/ActivateAccount.vue' /* webpackChunkName: "pages/ActivateAccount" */))
const _0db54ef6 = () => interopDefault(import('../pages/Adventures/index.vue' /* webpackChunkName: "pages/Adventures/index" */))
const _39e01a75 = () => interopDefault(import('../pages/Blog/index.vue' /* webpackChunkName: "pages/Blog/index" */))
const _c8cb93c2 = () => interopDefault(import('../pages/Brands/index.vue' /* webpackChunkName: "pages/Brands/index" */))
const _89ccd664 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "" */))
const _0e3e7dd4 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _1900d462 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _909fb326 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _46d3ee57 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _3061b9fe = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _f59c93a8 = () => interopDefault(import('../pages/Experts/index.vue' /* webpackChunkName: "pages/Experts/index" */))
const _17c50cae = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _58d8d98c = () => interopDefault(import('../pages/Healthcheck.vue' /* webpackChunkName: "pages/Healthcheck" */))
const _480c5fa2 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _7db5c76e = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _b8079662 = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _82cb1d42 = () => interopDefault(import('../pages/Pages/_slug.vue' /* webpackChunkName: "" */))
const _24ccad2c = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _b81ef310 = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _a04f4c30 = () => interopDefault(import('../pages/Signup.vue' /* webpackChunkName: "pages/Signup" */))
const _28cd3e9d = () => interopDefault(import('../pages/Adventures/_adventure.vue' /* webpackChunkName: "pages/Adventures/_adventure" */))
const _3829792d = () => interopDefault(import('../pages/Blog/_slug.vue' /* webpackChunkName: "pages/Blog/_slug" */))
const _422d3447 = () => interopDefault(import('../pages/Brands/_id.vue' /* webpackChunkName: "pages/Brands/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3085230d,
    name: "Account___en",
    children: [{
      path: "account-details",
      component: _3a7848e7,
      name: "Account-AccountDetails___en"
    }, {
      path: "addresses",
      component: _99283900,
      name: "Account-Addresses___en"
    }, {
      path: "logout-modal",
      component: _597f1121,
      name: "Account-LogoutModal___en"
    }, {
      path: "my-orders",
      component: _51d7a6c3,
      name: "Account-MyOrders___en"
    }, {
      path: "my-orders-returns-app",
      component: _7662c801,
      name: "Account-MyOrdersReturnsApp___en"
    }]
  }, {
    path: "/activate-account",
    component: _29c9281a,
    name: "ActivateAccount___en"
  }, {
    path: "/adventures",
    component: _0db54ef6,
    name: "Adventures___en"
  }, {
    path: "/blog",
    component: _39e01a75,
    name: "Blog___en"
  }, {
    path: "/brands",
    component: _c8cb93c2,
    name: "Brands___en"
  }, {
    path: "/c",
    component: _89ccd664,
    name: "categories___en"
  }, {
    path: "/category",
    component: _89ccd664,
    name: "Category___en"
  }, {
    path: "/checkout",
    component: _0e3e7dd4,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _1900d462,
      name: "billing___en"
    }, {
      path: "payment",
      component: _909fb326,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _46d3ee57,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _3061b9fe,
      name: "thank-you___en"
    }]
  }, {
    path: "/checkout",
    component: _0e3e7dd4,
    name: "Checkout___en",
    children: [{
      path: "billing",
      component: _1900d462,
      name: "Checkout-Billing___en"
    }, {
      path: "payment",
      component: _909fb326,
      name: "Checkout-Payment___en"
    }, {
      path: "shipping",
      component: _46d3ee57,
      name: "Checkout-Shipping___en"
    }, {
      path: "thank-you",
      component: _3061b9fe,
      name: "Checkout-ThankYou___en"
    }]
  }, {
    path: "/experts",
    component: _f59c93a8,
    name: "Experts___en"
  }, {
    path: "/forgot-password",
    component: _17c50cae,
    name: "ForgotPassword___en"
  }, {
    path: "/healthcheck",
    component: _58d8d98c,
    name: "Healthcheck___en"
  }, {
    path: "/home",
    component: _480c5fa2,
    name: "Home___en"
  }, {
    path: "/login",
    component: _7db5c76e,
    name: "Login___en"
  }, {
    path: "/product",
    component: _b8079662,
    name: "Product___en"
  }, {
    path: "/promotions",
    component: _82cb1d42,
    name: "promotions___en"
  }, {
    path: "/reset-password",
    component: _24ccad2c,
    name: "reset-password___en"
  }, {
    path: "/reset-password",
    component: _24ccad2c,
    name: "ResetPassword___en"
  }, {
    path: "/search",
    component: _b81ef310,
    name: "Search___en"
  }, {
    path: "/signup",
    component: _a04f4c30,
    name: "Signup___en"
  }, {
    path: "/adventures/:adventure",
    component: _28cd3e9d,
    name: "Adventures-adventure___en"
  }, {
    path: "/blog/:slug",
    component: _3829792d,
    name: "Blog-slug___en"
  }, {
    path: "/brands/:id",
    component: _422d3447,
    name: "Brands-id___en"
  }, {
    path: "/experts/:slug",
    component: _82cb1d42,
    name: "expert___en"
  }, {
    path: "/pages/:slug?",
    component: _82cb1d42,
    name: "Pages-slug___en"
  }, {
    path: "/products/:slug",
    component: _b8079662,
    name: "product___en"
  }, {
    path: "/activate-account/:customerId/:activationToken",
    component: _29c9281a,
    name: "activate-customer-account___en"
  }, {
    path: "/blog/:filterLabel/:filter+",
    component: _39e01a75,
    name: "blog___en"
  }, {
    path: "/reset-password/:customerId/:resetToken",
    component: _24ccad2c,
    name: "reset-customer-password___en"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _89ccd664,
    name: "category___en"
  }, {
    path: "/",
    component: _480c5fa2,
    name: "home___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
