var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('UCCardSection',{staticClass:"card-section",attrs:{"title":_vm.title,"mobile":{
      cardMode: 'scroll',
      cardFlexBasis: '20%',
      cardFlexGrow: 1,
      headerStyle: {
        textAlign: 'left',
      },
      headerLinkStyle: {
        textDecoration: 'underline',
      },
      groupStyle: {
        overflow: 'visible',
        margin: '0',
      },
    },"tablet":{
      cardMode: 'scroll',
      cardFlexBasis: '20%',
      headerStyle: {
        textAlign: 'left',
      },
      headerLinkStyle: {
        textDecoration: 'underline',
      },
      groupStyle: {
        overflow: 'visible',
        margin: '0',
      },
    },"desktop":{
      showSteppers: false,
      cardMode: 'wrap',
      cardFlexBasis: '20%',
      groupStyle: {
        overflow: 'visible',
        margin: '0',
      },
      headerStyle: {
        textAlign: 'left',
      },
      headerLinkStyle: {
        textDecoration: 'underline',
      },
    },"link":_vm.getUrlLink(_vm.link.cached_url),"link-text":_vm.linkText,"always-show-link-arrow":true},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.items),function(item){return _c('div',{key:item._uid,staticClass:"card"},[_c('div',{staticClass:"card-inner"},[(item.link.cached_url)?_c('BaseLink',{attrs:{"link":_vm.getUrlLink(item.link.cached_url)}},[_c('BaseImage',{staticClass:"image",attrs:{"src":item.image.url.filename,"alt":item.image.url.alt,"width":"100%","height":"100%"}})],1):_c('BaseImage',{staticClass:"image",attrs:{"src":item.image.url.filename,"alt":item.image.url.alt,"width":"100%","height":"100%"}})],1),_vm._v(" "),_c('div',{staticClass:"label-container"},[_c('label',{staticClass:"label",style:(_vm.getLabelColorStyle(item))},[_vm._v(_vm._s(item.label))])])])})},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }