import Vue from 'vue';

import AdventureTabs from '~/components/CMS/AdventureTabs/AdventureTabs.vue';
import Author from '~/components/CMS/Author/Author.vue';
import BlogProductsSlider from '~/components/CMS/BlogPost/BlogProductsSlider/BlogProductsSlider';
// import BlogPost from '~/cms/BlogPost';
import BlogPostTitle from '~/components/CMS/BlogPostTitle';
import BrandHero from '~/components/CMS/BrandHero/BrandHero';
import CardListWithHeading from '~/components/CMS/CardListWithHeading/CardListWithHeading';
import CategoryHero from '~/components/CMS/CategoryHero/CategoryHero.vue';
import ContactForm from '~/components/CMS/ContactForm/ContactForm';
import DescriptionCard from '~/components/CMS/DescriptionCard/DescriptionCard';
import ExpertHero from '~/components/CMS/ExpertHero/ExpertHero';
import ExpertsList from '~/components/CMS/ExpertsList/ExpertsList.vue';
import FAQList from '~/components/CMS/FAQList';
import FeaturedBlogHero from '~/components/CMS/FeaturedBlogHero/FeaturedBlogHero';
import FeaturedBlogs from '~/components/CMS/FeaturedBlogs/FeaturedBlogs';
import FeaturedPromotion from '~/components/CMS/FeaturedPromotion/FeaturedPromotion.vue';
import FeaturedYoutubeVideo from '~/components/CMS/FeaturedYoutubeVideo/FeaturedYoutubeVideo';
import HeroCarousel from '~/components/CMS/HeroCarousel/HeroCarousel.vue';
import HeroGallery from '~/components/CMS/HeroGallery/HeroGallery.vue';
import HeroImageTitle from '~/components/CMS/HeroImageTitle/HeroImageTitle.vue';
import HeroTitle from '~/components/CMS/HeroTitle';
import IconCardList from '~/components/CMS/IconCardList/IconCardList';
import IconGrid from '~/components/CMS/IconGrid/IconGrid.vue';
import ImageTextCTA from '~/components/CMS/ImageTextCTA/ImageTextCTA';
import InspirationGrid from '~/components/CMS/InspirationGrid/InspirationGrid';
import LargeContentCard from '~/components/CMS/LargeContentCard/LargeContentCard.vue';
import ProductCarouselAuto from '~/components/CMS/ProductCarouselAuto/ProductCarouselAuto';
import ProductCarouselManual from '~/components/CMS/ProductCarouselManual/ProductCarouselManual';
import PromotionCarousel from '~/components/CMS/PromotionCarousel/PromotionCarousel.vue';
import PromotionsGrid from '~/components/CMS/PromotionsGrid/PromotionsGrid.vue';
import QuoteBlockWithImage from '~/components/CMS/QuoteBlockWithImage/QuoteBlockWithImage';
import RichTextDisplay from '~/components/CMS/RichTextDisplay/RichTextDisplay.vue';
import SelectVehicle from '~/components/CMS/SelectVehicle/SelectVehicle.vue';
import ShopByBrand from '~/components/CMS/ShopByBrand/ShopByBrand';
import ShopByCategory from '~/components/CMS/ShopByCategory/ShopByCategory';
import SocialIcons from '~/components/CMS/SocialIcons/SocialIcons';
import TinyMCERenderer from '~/components/CMS/TinyMCERenderer/TinyMCERenderer.vue';
import YoutubeVideosDisplay from '~/components/CMS/YoutubeVideosDisplay/YoutubeVideosDisplay.vue';
import YoutubeVideosGallery from '~/components/CMS/YoutubeVideosGallery/YoutubeVideosGallery';
import Placeholder from '~/components/Placeholder.vue';

// V2 imports

// import ImageTextCTAV2 from '~/components/CMS/ImageTextCTAV2/ImageTextCTAV2';

Vue.component('BlogPostTitle', BlogPostTitle);
Vue.component('RichTextEditor', RichTextDisplay);
Vue.component('RichTextDisplay', RichTextDisplay);
Vue.component('BlogProductsSlider', BlogProductsSlider);
Vue.component('FAQList', FAQList);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Carousel', HeroCarousel);
Vue.component('HeroGallery', HeroGallery);
Vue.component('LargeContentCard', LargeContentCard);
Vue.component('DescriptionCard', DescriptionCard);
Vue.component('HeroImageTitle', HeroImageTitle);
Vue.component('HeroTitle', HeroTitle);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Author', Author);
Vue.component('ExpertsList', ExpertsList);
Vue.component('AdventureTabs', AdventureTabs);
Vue.component('YoutubeVideosGallery', YoutubeVideosGallery);
Vue.component('ImageTextCTA', ImageTextCTA);
Vue.component('FeaturedBlogHero', FeaturedBlogHero);
Vue.component('FeaturedBlogs', FeaturedBlogs);
Vue.component('IconCardList', IconCardList);
Vue.component('BrandHero', BrandHero);
Vue.component('ProductCarouselAuto', ProductCarouselAuto);
Vue.component('ShopByBrand', ShopByBrand);
Vue.component('ShopByCategory', ShopByCategory);
Vue.component('ExpertHero', ExpertHero);
Vue.component('FeaturedYoutubeVideo', FeaturedYoutubeVideo);
Vue.component('QuoteBlockWithImage', QuoteBlockWithImage);
Vue.component('CardListWithHeading', CardListWithHeading);
Vue.component('PromotionsGrid', PromotionsGrid);
Vue.component('FeaturedPromotion', FeaturedPromotion);
Vue.component('PromotionCarousel', PromotionCarousel);
Vue.component('ContactForm', ContactForm);
Vue.component('ProductCarouselManual', ProductCarouselManual);
Vue.component('CategoryHero', CategoryHero);
Vue.component('SelectVehicle', SelectVehicle);

// might not be necessary unless whitelisted to existing content types
Vue.component('FooterMenu', Placeholder);
Vue.component('SocialMediaLink', Placeholder);
Vue.component('SimpleNavigationMenuItem', Placeholder);
Vue.component('AdvancedRichTextEditor', TinyMCERenderer);

// V2 Designs
Vue.component('InspirationGrid', InspirationGrid);
Vue.component('SocialIcons', SocialIcons);
Vue.component('IconGrid', IconGrid);
// Vue.component('ImageTextCTA', ImageTextCTAV2);
Vue.component('YoutubeVideosDisplay', YoutubeVideosDisplay);
