/* eslint-disable no-magic-numbers */
import { IProductResult } from '@unified-commerce/gpc-vue-storefront-search-io';
import { cartGetters, Product, productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';
import { Cart } from '@unified-commerce/gpc-vue-storefront-shopify/api-client/src/types';

import { sanitiseProductId } from './shopify';

type EventName =
  | 'view_cart'
  | 'product_view'
  | 'product_category_view'
  | 'search_result_page_view'
  | 'add_to_cart'
  | 'select_item'
  | 'view_item_list'
  | 'view_item';
type ContentType = 'product';
type Currency = 'AUD';

interface Item {
  item_id: string;
  item_name?: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
}

export interface ShopifyProduct extends Partial<Product> {
  variantId?: string;
  name?: string;
  vendor?: string;
  link?: string;
}

interface AnalyticsEvent extends Partial<Item> {
  event: EventName;
  content_name?: string;
  content_category?: string;
  content_ids?: string[];
  content_type?: ContentType;
  value?: number;
  currency?: Currency;
  discount?: number;
  items?: Item[];
}

interface AddToCartPayload {
  event: 'add_to_cart';
  id?: string;
  name?: string;
  price?: number;
  vendor?: string;
  category?: string;
}

interface ViewCartPayload {
  event: 'view_cart';
  cart?: Cart;
}

interface ItemPayload {
  event: 'view_item' | 'select_item';
  product?: ShopifyProduct;
  discount?: number;
  price?: number;
}

interface ViewItemListPayload {
  event: 'view_item_list';
  id?: string;
  name?: string;
  items?: IProductResult[];
}

type AnalyticsEventPayload = ViewCartPayload | ViewItemListPayload | AddToCartPayload | ItemPayload;

const getAddToCardPayload = (payload: AddToCartPayload): AnalyticsEvent => {
  return {
    event: payload.event,
    content_type: 'product',
    currency: 'AUD',
    items: [
      {
        item_brand: payload.vendor ?? '',
        item_category: payload.category ?? '',
        item_id: sanitiseProductId(payload.id),
        item_name: payload.name ?? '',
        price: payload.price ?? 0,
      },
    ],
  };
};

const getViewCartPayload = (payload: ViewCartPayload): AnalyticsEvent => {
  return {
    event: payload.event,
    currency: 'AUD',
    value: cartGetters.getTotals(payload?.cart)?.subtotal ?? 0,
    items: payload?.cart
      ? cartGetters.getItems(payload.cart)?.map((item) => ({
          item_id: sanitiseProductId(item?.id ?? ''),
          item_name: item?.title ?? '',
          item_brand: cartGetters.getVendor(item) ?? '',
          item_variant: item?.id ?? '',
          price: cartGetters.getPrice(item) ?? 0,
          quantity: item?.quantity ?? 0,
        }))
      : [],
  };
};

const getItemPayload = (payload: ItemPayload): AnalyticsEvent => {
  const breadcrumbs = payload.product
    ? productGetters.getProductCategoryBreadcrumbs(payload.product as Product, '4WD247')
    : [];

  return {
    event: payload.event,
    currency: 'AUD',
    value: payload.price,
    items: [
      {
        discount: payload.discount,
        item_brand: payload.product?.vendor ?? '',
        item_category: breadcrumbs[breadcrumbs.length ? breadcrumbs.length - 1 : 0]?.text ?? '',
        item_id: sanitiseProductId(payload.product?.id),
        item_list_id: 'product',
        item_list_name: 'Product',
        item_name: payload.product?.name,
        price: payload.price,
      },
    ],
  };
};

const getViewItemListPayload = (payload: ViewItemListPayload): AnalyticsEvent => {
  return {
    event: payload.event,
    item_list_id: payload.id,
    item_list_name: payload.name,
    items: payload.items?.map(({ record }) => ({
      item_id: sanitiseProductId(record?.id),
      item_name: record?.title ?? '',
      item_brand: record?.vendor ?? '',
      item_category: payload.name,
      currency: 'AUD',
    })),
  };
};

/**
 * Sends an event payload to GTM for further processing.
 * To be added: Allows for server-side Facebook Conversion API triggering.
 */
const analytics = (eventPayload: AnalyticsEventPayload) => {
  let payload = {};

  switch (eventPayload.event) {
    case 'view_cart':
      payload = getViewCartPayload(eventPayload as ViewCartPayload);
      break;
    case 'view_item_list':
      payload = getViewItemListPayload(eventPayload as ViewItemListPayload);
      break;
    case 'add_to_cart':
      payload = getAddToCardPayload(eventPayload as AddToCartPayload);
      break;
    case 'view_item':
    case 'select_item':
      payload = getItemPayload(eventPayload as ItemPayload);
      break;
    default:
      payload = eventPayload;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(payload);
};

export default analytics;
