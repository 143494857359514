import { Collection, ISearchParams } from '@unified-commerce/gpc-vue-storefront-search-io';

const getFitmentWithAskuParams = (asku: string): ISearchParams => {
  return {
    query: asku,
    collection: Collection.FITMENT,
    filter: `asku = '${asku}'`,
  };
};

export default getFitmentWithAskuParams;
