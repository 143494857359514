export interface IArbitraryNestedObject {
  [key: string]: number | string | IArbitraryNestedObject | unknown | Array<any>;
}

function getByString(obj: IArbitraryNestedObject, path: string, backSteps = 0): any {
  const keys = path.split('.');
  keys.splice(keys.length - backSteps, backSteps);
  return keys.reduce((acc, curr) => {
    if (typeof acc === 'object' && typeof acc[curr] != undefined) {
      return acc[curr] as IArbitraryNestedObject;
    } else return acc;
  }, obj);
}

export default getByString;
