import { integrationPlugin } from '@unified-commerce/vue-storefront-integration-plugin';

const moduleOptions = JSON.parse('{"i18n":{"useNuxtI18nConfig":true},"locale":"en","currency":"AUD","country":"AU","acceptLanguage":["en"],"countries":[{"name":"AU","label":"Australia"}],"currencies":[{"name":"AU","label":"Dollar"}],"locales":[{"name":"en","label":"English"}]}');

const SP_TOKEN_COOKIE_NAME = 'vsf-shopify-token';

export default integrationPlugin(({ app, integration }) => {
  const onTokenChange = (newToken) => {
    try {
      const currentToken = app.$cookies.get(SP_TOKEN_COOKIE_NAME);

      if (!currentToken || currentToken.access_token !== newToken.access_token) {
        app.$cookies.set(SP_TOKEN_COOKIE_NAME, newToken);
      }
    } catch (e) {
      // Cookies on is set after request has sent.
    }
  };

  const onTokenRemove = () => {
    app.$cookies.remove(SP_TOKEN_COOKIE_NAME);
  };

  const onTokenRead = () => {
    return app.$cookies.get(SP_TOKEN_COOKIE_NAME);
  };

  integration.configure('shopify', {
    ...moduleOptions,
    app,
    additionalProperties: {
      auth: {
        onTokenChange,
        onTokenRead,
        onTokenRemove,
      },
    },
  });
});
