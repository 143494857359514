import { ICheckboxOption } from '../getFilterLink';
import { FACET_QUERY_PARAM_NAME } from '../getFilterLink/index';

const isFacetValueInQuery = (filter: ICheckboxOption, query: any) => {
  const facetQuery = query[FACET_QUERY_PARAM_NAME];
  if (facetQuery) {
    if (Array.isArray(facetQuery)) {
      return facetQuery.some((facet: any) => {
        return facet.includes(filter.value);
      });
    } else {
      return facetQuery.includes(filter.value);
    }
  } else {
    return false;
  }
};

export default isFacetValueInQuery;
