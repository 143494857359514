var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[(!_vm.isSignUpSuccessModal)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleRegister)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"email","label":"Your Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-firstName'),expression:"'login-modal-firstName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"first-name","label":"First Name"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"last-name","label":"Last Name"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"password","label":"Password","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),(_vm.error.register)?_c('div',{staticClass:"form-error"},[_vm._v("\n        "+_vm._s(_vm.error.register)+"\n      ")]):_vm._e(),_vm._v(" "),_c('BaseButton',{staticClass:"sf-button--full-width form__button",attrs:{"type":"submit","disabled":_vm.loading}},[_c('BaseLoader',{class:{ loader: _vm.loading || _vm.isLoginLoading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Create an account')))])])],1)],1)]}}],null,false,3367693239)}):_c('div',{staticClass:"signup-notification"},[_c('h2',{staticClass:"signup-notification-title"},[_vm._v("\n      "+_vm._s(_vm.notification.title)+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"signup-notification-message"},[_vm._v("\n      "+_vm._s(_vm.notification.message)+"\n    ")]),_vm._v(" "),(_vm.notification.loginError)?_c('p',{staticClass:"signup-notification-message form-error"},[_vm._v("\n      "+_vm._s(_vm.notification.loginError)+"\n    ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }