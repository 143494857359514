import { registerLogger } from '@vue-storefront/core'

const loggerPlugin = (ctx) => {
  const { verbosity, customLogger, ...args } = {"customLogger":() => {
        const formatAxiosError = (error) => {
          const config = {
            method: error.config.method,
            url: error.config.url,
            data: error.config.data,
            headers: error.config.headers,
          };
          if (error.response) {
            return {
              config,
              data: error.response.data,
              status: error.response.status,
              headers: error.response.headers,
            };
          } else {
            return {
              config,
              message: error.message,
            };
          }
        };

        const formatLogArguments = (type, message, ...args) => {
          const payload = args.reduce((accum, arg, index) => {
            /*
             * Adds a simple id to handle the edge case of multiple object or error arguments being
             * passed, e.g., 'data' is the standard case, then 'data-1', 'data-2' etc
             */
            const id = (key) => (accum[key] ? `-${index + 1}` : '');
            if (arg['isAxiosError']) {
              accum[`error${id('error')}`] = formatAxiosError(arg);
            } else if (arg instanceof Error) {
              // Special treatment for Error args since they can't simply be stringified
              accum[`error${id('error')}`] = JSON.parse(
                JSON.stringify(arg, Object.getOwnPropertyNames(arg)),
              );
            } else {
              accum[`data${id('data')}`] = arg;
            }
            return accum;
          }, {});

          return JSON.stringify({
            type,
            message,
            ...payload,
          });
        };

        const brand = process.env.TENANT ? `[${process.env.TENANT}]` : '';

        return {
          debug: (message, ...args) =>
            console.debug(formatLogArguments(`${brand}[VSF][debug]`, message, ...args)),
          info: (message, ...args) =>
            console.info(formatLogArguments(`${brand}[VSF][info]`, message, ...args)),
          warn: (message, ...args) =>
            console.warn(formatLogArguments(`${brand}[VSF][warn]`, message, ...args)),
          error: (message, ...args) =>
            console.error(formatLogArguments(`${brand}[VSF][error]`, message, ...args)),
        };
      }};
  registerLogger(customLogger || args, verbosity || 'error')
};

export default loggerPlugin;
