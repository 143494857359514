/* eslint-disable max-lines-per-function */
/* istanbul ignore file */
import { getCurrentInstance, Ref, unref } from '@nuxtjs/composition-api';
import {
  ISearchPriceAggregate,
  ISearchResponse,
} from '@unified-commerce/gpc-vue-storefront-search-io';
import { Category, Collection, Filter } from '@unified-commerce/gpc-vue-storefront-shopify';
import { AgnosticFacet, Logger } from '@vue-storefront/core';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import qs from 'qs';
import { Route } from 'vue-router';

import appendAutoplayToYouTubeEmbedURL from './appendAutoplayToYouTubeEmbedURL/index';
import getBrandLinkFromSlug from './getBrandLinkFromSlug';
import getByString from './getByString';
import getFilterLink from './getFilterLink/index';
import getPromosLinkFromSlug from './getPromosLinkFromSlug/index';
import getPromotionsCategoryLink from './getPromotionsCategoryLink';
import getSearchQueryLink from './getSearchQueryLink/index';
import getYouTubeEmbedURL from './getYoutubeEmbedURL/index';
import isFacetValueInQuery from './isFacetValueInQuery/index';
import { lockPageScrollAllViewports, lockPageScrollOnMobileOnly } from './lockPageScroll';
import { unlockPageScrollAllViewports, unlockPageScrollOnMobileOnly } from './unlockPageScroll';
const nonFilters = ['page', 'sort', 'term', 'itemsPerPage'];

export const SEARCH_CATEGORY_TENANT_PREFIX_TITLE = '4WD247 Categories';
export const SEARCH_CATEGORY_TENANT_PREFIX_HANDLE = '4wd247-categories-';
export const SEARCH_CATEGORY_PATH_SEPERATOR = '>';
export const TAG_LIMIT = 2;
export const RESERVED_TAG_PREFIX = 'sales_promo';
const SEARCH_TOP_LEVEL_PROMOTION_CATEGORY_TITLE = `${SEARCH_CATEGORY_TENANT_PREFIX_TITLE}>Promotions`;
const SEARCH_PROMOTION_ACTIVE_TAG = 'sales_promo_active';
const SEARCH_CATEGORY_HANDLE_SEPARATOR = '-';
const SEARCH_CATEGORY_QUERY_SEPARATOR = '/';
const SEARCH_CATEGORY_FILTER = 'collection_titles';
const SEARCH_BRAND_PREFIX_TITLE = '4WD247 Categories>Brand Categories';
const SEARCH_DEFAULT_TITLE_OPTION = 'Default Title';

const SEARCH_PRICE_FILTER = 'max_price';
const SEARCH_FILTER_TITLE_PREFIX = 'count.';
const SEARCH_FILTER_VARIANT_OPTION_PREFIX = 'variant_options';
const SEARCH_FILTER_PRODUCT_OPTION_PREFIX = 'option';
export const SEARCH_FILTERS_SEPARATOR = ',';

const CATEGORY_ROUTE_PREFIX = 'c';

const IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM = 'from-checkout';
const IS_LOGIN_FROM_CHECKOUT_COOKIE = 'isloginfromcheckout';
const PAGE_QUERY_PARAM = 'page';
export const CATEGORY_QUERY_PARAM = 'category';
const MIN_PRICE_QUERY_PARAM = 'min_price';
const MAX_PRICE_QUERY_PARAM = 'max_price';
const PRICE_RANGE_QUERY_PARAM = 'price-range';
const MAX_PRICE_RANGE_QUERY_PREFIX = 'up-to-';
const MIN_PRICE_RANGE_QUERY_PREFIX = 'above-';
const SEARCH_QUERY_PARAM = 'q';
const PRODUCT_TYPE_QUERY_PARAM = 'product_type';
const SIZE_OPTION_QUERY_PARAM = 'size';
const COLOUR_OPTION_QUERY_PARAM = 'colour';
const VENDOR_QUERY_PARAM = 'vendor';
const SORT_BY_QUERY_PARAM = 'sort-by';

const MAX_NUMBER_OF_CATEGORIES = 6;

const PROMOTION_CATEGORY_PREFIX_TITLE = 'Promotion';
const BRAND_CATEGORY_PREFIX_TITLE = 'Brand';

const ADVENTURE_CATEGORY = '4WD247 Categories>Adventures';

export const SEARCH_DEFAULT_FILTERS: ISearchFilters[] = [
  {
    filterKey: 'collection_titles',
    queryParameterName: CATEGORY_QUERY_PARAM,
    canProductHaveMultipleValues: true,
    extendWithPromotionFilter: true,
  },
  {
    filterKey: 'product_type',
    queryParameterName: PRODUCT_TYPE_QUERY_PARAM,
    canProductHaveMultipleValues: false,
  },
  {
    filterKey: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_1`,
    queryParameterName: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_1`,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_2`,
    queryParameterName: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_2`,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_3`,
    queryParameterName: `${SEARCH_FILTER_VARIANT_OPTION_PREFIX}_3`,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_PRODUCT_OPTION_PREFIX}_size`,
    queryParameterName: SIZE_OPTION_QUERY_PARAM,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: `${SEARCH_FILTER_PRODUCT_OPTION_PREFIX}_colour`,
    queryParameterName: COLOUR_OPTION_QUERY_PARAM,
    canProductHaveMultipleValues: true,
  },
  {
    filterKey: 'vendor',
    queryParameterName: VENDOR_QUERY_PARAM,
    canProductHaveMultipleValues: false,
    label: 'Brand',
  },
];

const SEARCH_PRICE_RANGES = [
  {
    maxPrice: 25,
  },
  {
    minPrice: 25,
    maxPrice: 50,
  },
  {
    minPrice: 50,
    maxPrice: 100,
  },
  {
    minPrice: 100,
    maxPrice: 250,
  },
  {
    minPrice: 250,
    maxPrice: 1000,
  },
  {
    minPrice: 1000,
    maxPrice: 5000,
  },
  {
    minPrice: 5000,
    maxPrice: 10000,
  },
  {
    minPrice: 10000,
  },
];

const SORT_OPTION_LIST = [
  {
    label: 'Most Relevant',
    value: 'most_relevant',
  },
  {
    label: 'Alphabetically, A - Z',
    value: 'title',
    queryParamName: 'title-ascending',
  },
  {
    label: 'Alphabetically, Z - A',
    value: '-title',
    queryParamName: 'title-descending',
  },
  {
    label: 'Price, low - high',
    value: 'max_price',
    queryParamName: 'price-ascending',
  },
  {
    label: 'Price, high - low',
    value: '-max_price',
    queryParamName: 'price-descending',
  },
  {
    label: 'Date, old - new',
    value: 'created_at',
    queryParamName: 'created-ascending',
  },
  {
    label: 'Date, new - old',
    value: '-created_at',
    queryParamName: 'created-descending',
  },
];

export interface ISearchFilters {
  filterKey: string;
  queryParameterName: string;
  canProductHaveMultipleValues: boolean;
  label?: string;
  extendWithPromotionFilter?: boolean;
}

export interface ISearchPriceRange {
  minPrice?: number;
  maxPrice?: number;
}

export interface ISearchPriceRangeFilter {
  priceRanges: ISearchPriceRange[];
  minPrice: number;
  maxPrice: number;
  appliedPriceRanges: ISearchPriceRange[];
}

export interface ISearchFilterResult {
  exactTitle: string;
  encodedTitle: string;
  kebabTitle: string;
  productCount: number;
}

export interface ISearchCategoryFilter {
  exactHandle: string;
  exactTitle: string;
  encodedHandle: string;
  level: number;
  urlPath: string;
  productCount: number;
  ancestors: string[];
}

export interface ISearchFacetFilter extends ISearchFilterResult {
  options: ISearchFacetFilter[];
}

export interface Tag {
  label: string;
  backgroundColor: string;
  color: string;
}

interface ICTAStoryblokButtonLink {
  cached_url: string;
  fieldtype: string;
  id: string;
  linktype: string;
  url: string;
}

interface IRouteError {
  errorMessage?: string;
  data?: unknown;
}

const getContext = () => {
  const vm = getCurrentInstance();

  if (vm) {
    return vm.root.proxy;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduceFilters = (query: any) => (prev: any, curr: string) => {
  const makeArray = Array.isArray(query[curr]) || nonFilters.includes(curr);

  return {
    ...prev,
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const getFiltersDataFromUrl = (context: Vue, onlyFilters: boolean) => {
  const { query } = context.$router.currentRoute;

  return Object.keys(query)
    .filter((f) => (onlyFilters ? !nonFilters.includes(f) : nonFilters.includes(f)))
    .reduce(reduceFilters(query), {});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUiHelpers = () => {
  const context = getContext();

  const getCurrentRoute = () => {
    if (!context) {
      return;
    }
    return Object.assign({}, context.$router.currentRoute);
  };

  const getQueryURL = (paramsToRemove: string[], queriesToAdd: Record<string, string> = {}) => {
    if (!context) {
      return;
    }
    const currentQuery = { ...context.$router.currentRoute.query };
    const currentPath = context.$router.currentRoute.path;
    paramsToRemove.forEach((param) => {
      delete currentQuery[param];
    });

    const queryURL =
      currentPath + qs.stringify({ ...currentQuery, ...queriesToAdd }, { addQueryPrefix: true });
    return queryURL;
  };
  const setCurrentRouteWithError = (
    statusCode: number,
    errorMessage: string,
    data?: unknown,
  ): void => {
    if (!context) {
      return;
    }

    const { fullPath, ...route } = context.$route;

    const errorContext: Record<string, unknown> = {
      statusCode,
      errorMessage,
      route,
    };

    if (data) {
      errorContext.data = data;
    }

    context.$nuxt.error({
      statusCode,
      message: errorMessage,
    });
  };

  const setCurrentRouteAsNotFound = (errorParams?: IRouteError): void => {
    const errorMessage = errorParams?.errorMessage || 'This page could not be found';
    Logger.error(errorMessage);
    setCurrentRouteWithError(404, errorMessage, errorParams?.data);
  };

  const setCurrentRouteAsInternalError = (errorParams?: IRouteError): void => {
    const errorMessage = errorParams?.errorMessage || 'Something went wrong';

    setCurrentRouteWithError(500, errorMessage, errorParams?.data);
  };

  const getQueryParam = (queryParamName: string): string | null => {
    if (!context) {
      return null;
    }

    const { query } = context.$route;

    if (query[queryParamName]) {
      return decodeURIComponent(query[queryParamName].toString());
    }

    return '';
  };

  const getCurrentRouteParams = (): Record<string, string> | undefined | null => {
    if (!context) {
      return;
    }

    const { params } = context.$router.currentRoute;

    if (Object.entries(params).length === 0) {
      return null;
    }

    return params;
  };

  const getCurrentQuery = (): Record<string, string | (string | null)[]> | undefined | null => {
    if (!context) {
      return;
    }

    const { query } = context.$router.currentRoute;

    if (Object.entries(query).length === 0) {
      return null;
    }

    return query;
  };
  const getPathParam = (pathParamName: string): string => {
    if (!context) {
      return '';
    }

    const { params } = context.$route;

    return params[pathParamName];
  };

  const addQueryParam = (
    queryParamName: string,
    queryParamValue: string,
    replaceAllCurrentParams = false,
  ) => {
    if (!context) {
      return '';
    }

    const { query } = context.$route;

    return qs.stringify(
      replaceAllCurrentParams
        ? {
            [queryParamName]: queryParamValue,
          }
        : {
            ...query,
            [queryParamName]: queryParamValue,
          },
      {
        addQueryPrefix: true,
      },
    );
  };

  const setQueryParamInCurrentPath = async (
    queryParamName: string,
    queryParamValue: string,
  ): Promise<void> => {
    if (!context) {
      return;
    }

    await context.$router.push({
      path: context.$route.path,
      query: {
        ...context.$route.query,
        [queryParamName]: queryParamValue,
      },
    });
  };

  const removeQueryParam = (queryParamName: string) => {
    if (!context) {
      return '';
    }

    const { query, path } = context.$route;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [queryParamName]: _, ...restOfQueryParams } = query;

    const queryString = qs.stringify(restOfQueryParams, {
      addQueryPrefix: true,
    });

    if (!queryString) {
      return path;
    }

    return queryString;
  };

  const getFacetsFromURL = () => {
    if (!context) return;
    const { query, params } = context.$router.currentRoute;
    const categorySlug = Object.keys(params).reduce(
      (prev, curr) => params[curr] || prev,
      params.slug_1,
    );

    return {
      rootCatSlug: params.slug_1,
      categorySlug,
      page: parseInt(query.page as string, 10) || 1,
      sort: query.sort || 'latest',
      filters: getFiltersDataFromUrl(context, true),
      itemsPerPage: parseInt(query.itemsPerPage as string, 12) || 20,
      term: query.term,
    };
  };

  const getCatLink = (category: Category): string => {
    return `/c/${category.slug}`;
  };

  /**
   * **NOTE**: This function can only be used on the client-side, where the `window` object is defined.
   *
   * It can be used in `methods` or in client-side lifecycle hooks such as `mounted`.
   */
  const getCurrentAbsoluteURL = (): string => {
    if (!context) {
      return '';
    }

    const origin = window.location.origin;
    const routePath = context.$route.path;

    return `${origin}${routePath}`;
  };

  const getFacebookShareURL = (url: string, quote: string): string => {
    const baseURL = 'https://www.facebook.com/sharer.php';

    const queryParams = qs.stringify(
      {
        u: url,
        quote,
      },
      {
        addQueryPrefix: true,
      },
    );

    return `${baseURL}${queryParams}`;
  };

  const getInstagramShareURL = (url: string, quote: string): string => {
    const baseURL = 'https://instagram.com';

    const queryParams = qs.stringify(
      {
        u: url,
        quote,
      },
      {
        addQueryPrefix: true,
      },
    );

    return `${baseURL}${queryParams}`;
  };

  const getTwitterShareURL = (url: string, quote: string): string => {
    const baseURL = 'https://twitter.com/share';

    const queryParams = qs.stringify(
      {
        url,
        text: quote,
      },
      {
        addQueryPrefix: true,
      },
    );

    return `${baseURL}${queryParams}`;
  };

  const changeSorting = (sort: string) => {
    if (!context) return;
    const { query } = context.$router.currentRoute;
    context.$router.push({ query: { ...query, sort } });
  };

  const changeFilters = (filters: Filter[]) => {
    if (!context) return;
    context.$router.push({
      query: {
        ...getFiltersDataFromUrl(context, false),
        ...filters,
      },
    });
  };

  const changeItemsPerPage = (itemsPerPage: number) => {
    if (!context) return;
    context.$router.push({
      query: {
        ...getFiltersDataFromUrl(context, false),
        itemsPerPage,
      },
    });
  };

  const changeSearchTerm = (term: string) => {
    if (!context) return;
    context.$router.push({
      query: {
        ...getFiltersDataFromUrl(context, false),
        term: term || undefined,
      },
    });
  };

  const getCurrentPage = (pageQueryParamValue: string): number => {
    return pageQueryParamValue ? parseInt(pageQueryParamValue, 10) : 1;
  };

  const getSearchFiltersKeys = (): string => {
    return SEARCH_DEFAULT_FILTERS.map((filter) => filter.filterKey).join(SEARCH_FILTERS_SEPARATOR);
  };

  const getSortLink = (
    queryParams: Record<string, string | (string | null)[]>,
    sortOption: string,
  ): string => {
    return qs.stringify(
      {
        ...queryParams,
        ...(queryParams[PAGE_QUERY_PARAM] && {
          page: 1,
        }),
        [SORT_BY_QUERY_PARAM]: sortOption || [],
      },
      {
        addQueryPrefix: true,
      },
    );
  };

  const getCurrentSortOption = (query: Record<string, string | (string | null)[]>): string => {
    const sortQueryParam = query[SORT_BY_QUERY_PARAM];
    if (sortQueryParam) {
      return decodeURIComponent(sortQueryParam.toString());
    }
    return '';
  };

  /**
   * Product Information Mangement (PIM) integration pushes to Shopify category hierarchy. E.g. `"4WD247 Categories>Audio & Navigation>Navigation"`.
   *
   * It flattens the hierarchy to a single string since Shopify has no concept of nested categories.
   *
   * In UI app we need to account for that when rebuilding UI interface elements like routes for category levels, breadcrumbs etc.
   *
   * Also, all brands have concept of the parent category which is used only to differentiate between different brands. For 4x4 it is `"4WD247 Categories"`.
   */
  const getCategoryExactTitle = (categoryTitle: string): string => {
    const titles = categoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR);

    if (titles.length === 1) {
      return titles.join('');
    }

    const [, ...categoryTitles] = titles;

    return categoryTitles[categoryTitles.length - 1];
  };

  const getAnyCategoryLink = (
    path: string,
    queryParams: Record<string, string | (string | null)[]>,
    useQueryForCategoryFilters = true,
    basePath = CATEGORY_ROUTE_PREFIX,
  ): string => {
    const { [CATEGORY_QUERY_PARAM]: _, ...restOfQueryParams } = queryParams;
    const queryParamsString = qs.stringify(restOfQueryParams, {
      addQueryPrefix: true,
    });

    if (useQueryForCategoryFilters) {
      return `${path}${queryParamsString}`;
    }

    return `/${basePath}${queryParamsString}`;
  };

  const getCategoryLink = (
    queryParams: Record<string, string | (string | null)[]>,
    category: ISearchCategoryFilter,
    useQueryForCategoryFilters = true,
    fromSearchResults = false,
    basePath = CATEGORY_ROUTE_PREFIX,
  ): string => {
    const { [PAGE_QUERY_PARAM]: _, ...queryParamsWithoutPage } = queryParams;
    if (useQueryForCategoryFilters && !fromSearchResults) {
      return qs.stringify(
        {
          ...queryParamsWithoutPage,
          [CATEGORY_QUERY_PARAM]: category.encodedHandle,
        },
        {
          addQueryPrefix: true,
        },
      );
    } else if (!useQueryForCategoryFilters && !fromSearchResults) {
      const queryParamsString = qs.stringify(queryParamsWithoutPage, { addQueryPrefix: true });

      return `/${basePath}${category.urlPath}${queryParamsString}`;
    } else {
      return `/${basePath}${category.urlPath}`;
    }
  };

  const isCategoriesRoute = (routeArg: Ref<Route> | Route) => {
    const route = unref(routeArg);
    return route.path.startsWith(`/${CATEGORY_ROUTE_PREFIX}`);
  };
  const getCategoryFromVueRouteQuery = (routeArg: Ref<Route> | Route) => {
    const route = unref(routeArg);
    const categoryQueryParam = route.query[CATEGORY_QUERY_PARAM] ?? '';
    return decodeURIComponent(categoryQueryParam.toString())
      .split(SEARCH_CATEGORY_QUERY_SEPARATOR)
      .join(SEARCH_CATEGORY_HANDLE_SEPARATOR);
  };

  const getCategoryFromVueRouteParams = (routeArg: Ref<Route> | Route) => {
    const route = unref(routeArg);
    const categoryParams = Object.values(route.params).filter((param) => !!param);
    return categoryParams.join(SEARCH_CATEGORY_HANDLE_SEPARATOR);
  };

  const getActiveCategory = (route: Ref<Route> | Route): string => {
    if (isCategoriesRoute(route)) {
      return getCategoryFromVueRouteParams(route);
    }
    return getCategoryFromVueRouteQuery(route);
  };

  const getActiveCategoryTitle = (
    categoryFilters: ISearchCategoryFilter[],
    categoryHandle: string,
  ): string =>
    categoryFilters.find((categoryFilter) => categoryFilter.exactHandle === categoryHandle)
      ?.exactTitle || '';

  const getPromotionCategoryExactTitle = (categoryTitle: string): string => {
    if (categoryTitle.includes(PROMOTION_CATEGORY_PREFIX_TITLE)) {
      return categoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR).pop() || '';
    }

    return '';
  };

  const getBrandCategoryExactTitle = (categoryTitle: string): string => {
    if (categoryTitle.includes(BRAND_CATEGORY_PREFIX_TITLE)) {
      return categoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR).pop() || '';
    }

    return '';
  };

  const getEncodedCategoryHandle = (categoryHandle: string): string => {
    return encodeURIComponent(
      categoryHandle.split(SEARCH_CATEGORY_HANDLE_SEPARATOR).join(SEARCH_CATEGORY_QUERY_SEPARATOR),
    );
  };

  const getCategoryURLPath = (ancestorHandles: string[], categoryHandle: string): string => {
    const filteredHandles = ancestorHandles.filter((handle) => handle !== '');

    if (filteredHandles.length > 0) {
      const urlPaths = [...filteredHandles, categoryHandle].map((handle, i, handles) => {
        const previousHandle = handles[i - 1];

        return handle.replace(previousHandle + SEARCH_CATEGORY_HANDLE_SEPARATOR, '');
      });

      return `/${urlPaths.join('/')}`;
    }

    return `/${categoryHandle}`;
  };

  const getSearchCategoryFilters = (
    searchQueryResponse: ISearchResponse,
    shopifyCategoriesResponse: readonly Collection[],
    fromSearchResults = false,
    preAppliedCategory = '',
  ): ISearchCategoryFilter[] => {
    const filterKey = `${SEARCH_FILTER_TITLE_PREFIX}${SEARCH_CATEGORY_FILTER}`;

    const searchQueryCategoryFilters =
      searchQueryResponse?.aggregateFilters?.[filterKey]?.count?.counts;

    if (!searchQueryCategoryFilters) {
      return [];
    }

    const searchCategoryFilters = Object.entries(searchQueryCategoryFilters)
      .reduce(
        (searchCategoryFilters: ISearchCategoryFilter[], [searchCategoryTitle, productCount]) => {
          const matchedShopifyCategory = shopifyCategoriesResponse.find(
            (shopifyCategory) => shopifyCategory.title === searchCategoryTitle,
          );

          const includedCategory = (categoryTitle: string): boolean => {
            return (
              categoryTitle.startsWith(SEARCH_CATEGORY_TENANT_PREFIX_TITLE) &&
              categoryTitle.includes(SEARCH_CATEGORY_PATH_SEPERATOR) &&
              !categoryTitle.includes(SEARCH_BRAND_PREFIX_TITLE)
            );
          };

          if (includedCategory(searchCategoryTitle) && !!matchedShopifyCategory) {
            const exactTitle = getCategoryExactTitle(searchCategoryTitle);

            const level = searchCategoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR).length - 1;

            let ancestors: string[] = [];
            if (level > 1) {
              const ancestorsTitles = searchCategoryTitle.split(SEARCH_CATEGORY_PATH_SEPERATOR);
              ancestorsTitles.shift();
              ancestorsTitles.pop();

              const ancestorHandles = ancestorsTitles.map((ancestorTitle, index) => {
                const lineage = ancestorsTitles.slice(0, index + 1);
                const ancestorTitleWithTenantPrefix = `${SEARCH_CATEGORY_TENANT_PREFIX_TITLE}${SEARCH_CATEGORY_PATH_SEPERATOR}${lineage.join(
                  SEARCH_CATEGORY_PATH_SEPERATOR,
                )}`;

                const matchedAncestorShopifyCategory = shopifyCategoriesResponse.find(
                  (shopifyCategory) => shopifyCategory.title === ancestorTitleWithTenantPrefix,
                );

                return (
                  matchedAncestorShopifyCategory?.handle?.replace(
                    SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
                    '',
                  ) || ''
                );
              });

              ancestors = ancestorHandles;
            }

            const exactHandle = matchedShopifyCategory.handle.replace(
              SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
              '',
            );

            searchCategoryFilters.push({
              encodedHandle: getEncodedCategoryHandle(exactHandle),
              exactHandle,
              urlPath: getCategoryURLPath(ancestors, exactHandle),
              exactTitle,
              level,
              ancestors,
              productCount:
                searchCategoryTitle in searchQueryCategoryFilters
                  ? searchQueryCategoryFilters[searchCategoryTitle]
                  : productCount,
            });
          }

          return searchCategoryFilters;
        },
        [],
      )
      .sort((categoryA: ISearchCategoryFilter, categoryZ: ISearchCategoryFilter) =>
        categoryA.exactHandle.localeCompare(categoryZ.exactHandle),
      )
      .filter((category) => {
        return !preAppliedCategory.includes(category.exactTitle);
      });

    if (fromSearchResults) {
      return searchCategoryFilters
        .sort((a, b) => b.productCount - a.productCount)
        .slice(0, MAX_NUMBER_OF_CATEGORIES);
    }

    return searchCategoryFilters;
  };

  // eslint-disable-next-line max-lines-per-function
  const getSearchFacetFilters = (searchQueryResponse: ISearchResponse) => {
    if (!searchQueryResponse.aggregateFilters) {
      return [];
    }

    const aggregateFilters = Object.entries(searchQueryResponse.aggregateFilters);

    const variantOptionsAggregateFilters = aggregateFilters.filter(([filterTitle]) =>
      filterTitle.startsWith(`${SEARCH_FILTER_TITLE_PREFIX}${SEARCH_FILTER_VARIANT_OPTION_PREFIX}`),
    );

    return aggregateFilters
      .filter(
        ([filterTitle]) =>
          !filterTitle.includes(SEARCH_CATEGORY_FILTER) &&
          !filterTitle.startsWith(
            `${SEARCH_FILTER_TITLE_PREFIX}${SEARCH_FILTER_VARIANT_OPTION_PREFIX}`,
          ),
      )
      .map(([filterTitle, filterOptions]) => {
        const trimmedPrefixTitle = filterTitle
          .replace(SEARCH_FILTER_TITLE_PREFIX, '')
          .replace(`${SEARCH_FILTER_PRODUCT_OPTION_PREFIX}_`, '');

        let filterOptionsAggregate: any[] = Object.entries(filterOptions.count.counts);

        if (
          filterTitle.startsWith(
            `${SEARCH_FILTER_TITLE_PREFIX}${SEARCH_FILTER_PRODUCT_OPTION_PREFIX}`,
          )
        ) {
          filterOptionsAggregate = filterOptionsAggregate.reduce(
            (matchedWithVariantOptions: unknown[], [optionTitle]) => {
              for (const [, variantAggregate] of variantOptionsAggregateFilters) {
                const variantAggregateValues = variantAggregate.count.counts;

                if (variantAggregateValues[optionTitle]) {
                  return [
                    ...matchedWithVariantOptions,
                    [optionTitle, variantAggregateValues[optionTitle]],
                  ];
                }
              }

              return matchedWithVariantOptions;
            },
            [],
          );
        }

        const options = filterOptionsAggregate
          .filter(
            ([optionTitle]: string[]) =>
              !!optionTitle && optionTitle !== SEARCH_DEFAULT_TITLE_OPTION,
          )
          .map(([optionTitle, productCount]: [string, number]) => ({
            encodedTitle: encodeURIComponent(optionTitle),
            exactTitle: optionTitle,
            productCount,
          }));

        return {
          encodedTitle: encodeURIComponent(trimmedPrefixTitle),
          exactTitle: startCase(trimmedPrefixTitle),
          options,
        };
      })
      .filter((facetFilter) => facetFilter.options.length > 0);
  };

  const getPriceRangeQueryValue = (priceRange: ISearchPriceRange): string => {
    if (priceRange.maxPrice && !priceRange.minPrice) {
      return `${MAX_PRICE_RANGE_QUERY_PREFIX}$${priceRange.maxPrice}`;
    } else if (priceRange.minPrice && !priceRange.maxPrice) {
      return `${MIN_PRICE_RANGE_QUERY_PREFIX}$${priceRange.minPrice}`;
    }

    return `$${priceRange.minPrice}-$${priceRange.maxPrice}`;
  };

  const getAppliedPriceRanges = (priceRangesQueryParam?: string) => {
    if (!context && !priceRangesQueryParam) {
      return [];
    }

    const parsePrice = (priceString: string): number => parseInt(priceString.replace('$', ''), 10);

    const appliedPriceRangesQueryParam =
      priceRangesQueryParam || getQueryParam(PRICE_RANGE_QUERY_PARAM);

    if (appliedPriceRangesQueryParam) {
      const priceRanges = appliedPriceRangesQueryParam.split(',');

      return priceRanges.map((priceRange) => {
        if (priceRange.startsWith(MAX_PRICE_RANGE_QUERY_PREFIX)) {
          const maxPrice = parsePrice(priceRange.replace(MAX_PRICE_RANGE_QUERY_PREFIX, ''));

          return {
            maxPrice,
          };
        } else if (priceRange.startsWith(MIN_PRICE_RANGE_QUERY_PREFIX)) {
          const minPrice = parsePrice(priceRange.replace(MIN_PRICE_RANGE_QUERY_PREFIX, ''));

          return {
            minPrice,
          };
        }

        const [minPrice, maxPrice] = priceRange.split('-');

        return {
          minPrice: parsePrice(minPrice),
          maxPrice: parsePrice(maxPrice),
        };
      });
    }

    return [];
  };

  const getSearchPriceRangeFilter = (
    searchResultsResponse: ISearchResponse,
  ): ISearchPriceRangeFilter => {
    const minPriceAggregate = searchResultsResponse?.aggregates?.[
      `min.${SEARCH_PRICE_FILTER}`
    ] as ISearchPriceAggregate;

    const maxPriceAggregate = searchResultsResponse?.aggregates?.[
      `max.${SEARCH_PRICE_FILTER}`
    ] as ISearchPriceAggregate;

    const appliedPriceRanges = getAppliedPriceRanges();

    const minPrice = minPriceAggregate?.metric?.value || 0;
    const maxPrice = maxPriceAggregate?.metric?.value || 0;

    const priceRanges = SEARCH_PRICE_RANGES.filter((priceRange) => {
      if (!priceRange.minPrice && priceRange.maxPrice) {
        return minPrice <= priceRange.maxPrice;
      } else if (priceRange.minPrice && !priceRange.maxPrice) {
        return maxPrice >= priceRange.minPrice;
      } else if (priceRange.minPrice && priceRange.maxPrice) {
        return minPrice <= priceRange.maxPrice && maxPrice >= priceRange.minPrice;
      }

      return false;
    });

    return {
      priceRanges,
      minPrice,
      maxPrice,
      appliedPriceRanges,
    };
  };

  const getNewPriceRangeQuery = (
    routeQuery: Record<string, string | (string | null)[]>,
    newPriceRange: ISearchPriceRange,
  ): string[] => {
    const currentPriceRangeQueryValue = routeQuery[PRICE_RANGE_QUERY_PARAM]?.toString() || '';

    const newPriceRangeQueryValue = getPriceRangeQueryValue(newPriceRange);

    const isInCurrentPriceRangeQuery =
      currentPriceRangeQueryValue.includes(newPriceRangeQueryValue);

    if (isInCurrentPriceRangeQuery) {
      if (currentPriceRangeQueryValue === newPriceRangeQueryValue) {
        return [];
      } else {
        return currentPriceRangeQueryValue
          .split(',')
          .filter((appliedPriceRange) => appliedPriceRange !== newPriceRangeQueryValue);
      }
    } else if (currentPriceRangeQueryValue) {
      return [currentPriceRangeQueryValue, newPriceRangeQueryValue];
    }

    return [newPriceRangeQueryValue];
  };

  const setPriceRangeQuery = async (priceRange: ISearchPriceRange) => {
    if (!context) {
      return;
    }

    context.$router.push({
      query: {
        ...context.$route.query,
        [PRICE_RANGE_QUERY_PARAM]: getPriceRangeQueryValue(priceRange),
      },
    });
  };

  const getSearchPriceRangeFilterValue = (priceRanges: ISearchPriceRange[]): string => {
    return priceRanges
      .map((priceRange) => {
        if (priceRange.maxPrice && priceRange.minPrice) {
          return `(${SEARCH_PRICE_FILTER} >= ${priceRange.minPrice} AND ${SEARCH_PRICE_FILTER} <= ${priceRange.maxPrice})`;
        } else if (!priceRange.maxPrice && priceRange.minPrice) {
          return `(${SEARCH_PRICE_FILTER} >= ${priceRange.minPrice})`;
        } else if (!priceRange.minPrice && priceRange.maxPrice) {
          return `(${SEARCH_PRICE_FILTER} <= ${priceRange.maxPrice})`;
        }

        return '';
      })
      .join(' OR ');
  };

  const getShopifyCategoryInStory = (
    story: Record<string, unknown>,
    componentName: string,
    shopifyCategoryFieldName = 'product_category',
    shopifyCategoryPluginName = 'shopify_category',
  ) => {
    return get(
      story,
      `content[0].${componentName}.content.${shopifyCategoryFieldName}[0].${shopifyCategoryPluginName}.items[0]`,
      null,
    ) as Record<string, string> | null;
  };

  const isFacetColor = (facet: AgnosticFacet): boolean => facet.id === 'color';

  const isFacetCheckbox = (): boolean => false;

  const getFirstName = (fullName: string) => fullName.split(' ')[0];

  const getCookieName = (appIdentifier: string, cookieName: string): string => {
    return `${appIdentifier}_${cookieName}`;
  };

  const getCTALink = (buttonLink: ICTAStoryblokButtonLink) => {
    if (buttonLink?.url.includes('categories') || buttonLink?.cached_url.includes('categories')) {
      return (
        buttonLink?.url.replace('categories/', 'c/') ||
        buttonLink?.cached_url.replace('categories/', 'c/')
      );
    }
    return buttonLink?.url || buttonLink?.cached_url;
  };

  const getProductCardTags = (tags: string[], saleTag?: string, saleTagColor?: string): Tag[] => {
    const cardTags = [];

    if (saleTag) {
      cardTags.push({
        label: saleTag.toLowerCase(),
        backgroundColor: saleTagColor || '#fae803',
        color: 'black',
      });
    }

    // eslint-disable-next-line no-magic-numbers
    const limitedTags = tags.slice(0, TAG_LIMIT).filter((t) => !t.startsWith(RESERVED_TAG_PREFIX));

    const customTags = limitedTags.map((tag) => ({
      label: tag.toLowerCase(),
      backgroundColor: 'black',
      color: 'white',
    })) as Tag[];

    cardTags.push(...customTags);

    return cardTags;
  };

  return {
    IS_LOGIN_FROM_CHECKOUT_QUERY_PARAM,
    IS_LOGIN_FROM_CHECKOUT_COOKIE,
    PAGE_QUERY_PARAM,
    SEARCH_CATEGORY_PATH_SEPERATOR,
    SEARCH_CATEGORY_HANDLE_SEPARATOR,
    CATEGORY_QUERY_PARAM,
    CATEGORY_ROUTE_PREFIX,
    SEARCH_CATEGORY_TENANT_PREFIX_TITLE,
    SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
    PRICE_RANGE_QUERY_PARAM,
    MIN_PRICE_QUERY_PARAM,
    MAX_PRICE_QUERY_PARAM,
    SIZE_OPTION_QUERY_PARAM,
    COLOUR_OPTION_QUERY_PARAM,
    VENDOR_QUERY_PARAM,
    PRODUCT_TYPE_QUERY_PARAM,
    SEARCH_QUERY_PARAM,
    SEARCH_CATEGORY_FILTER,
    SEARCH_TOP_LEVEL_PROMOTION_CATEGORY_TITLE,
    SEARCH_PROMOTION_ACTIVE_TAG,
    SEARCH_PRICE_FILTER,
    ADVENTURE_CATEGORY,
    SEARCH_DEFAULT_FILTERS,
    SORT_BY_QUERY_PARAM,
    SORT_OPTION_LIST,
    SEARCH_BRAND_PREFIX_TITLE,
    getActiveCategory,
    getAnyCategoryLink,
    getEncodedCategoryHandle,
    getCurrentPage,
    getSearchFiltersKeys,
    getSearchCategoryFilters,
    getSearchFacetFilters,
    getSearchPriceRangeFilter,
    getAppliedPriceRanges,
    getNewPriceRangeQuery,
    getPriceRangeQueryValue,
    setPriceRangeQuery,
    getSearchPriceRangeFilterValue,
    getActiveCategoryTitle,
    getPromotionCategoryExactTitle,
    getBrandCategoryExactTitle,
    setCurrentRouteAsNotFound,
    setCurrentRouteAsInternalError,
    getPathParam,
    getQueryParam,
    getCurrentQuery,
    addQueryParam,
    removeQueryParam,
    getQueryURL,
    getFacetsFromURL,
    getCatLink,
    changeSorting,
    changeFilters,
    changeItemsPerPage,
    changeSearchTerm,
    isFacetColor,
    isFacetCheckbox,
    getYouTubeEmbedURL,
    appendAutoplayToYouTubeEmbedURL,
    getCategoryLink,
    getFilterLink,
    isFacetValueInQuery,
    getBrandLinkFromSlug,
    getCurrentAbsoluteURL,
    getFacebookShareURL,
    getInstagramShareURL,
    getTwitterShareURL,
    getByString,
    getFirstName,
    getPromosLinkFromSlug,
    getPromotionsCategoryLink,
    getCurrentRouteParams,
    getCurrentRoute,
    setQueryParamInCurrentPath,
    getSearchQueryLink,
    getCategoryExactTitle,
    getShopifyCategoryInStory,
    getCookieName,
    getSortLink,
    getCurrentSortOption,
    lockPageScrollOnMobileOnly,
    lockPageScrollAllViewports,
    unlockPageScrollOnMobileOnly,
    unlockPageScrollAllViewports,
    getCTALink,
    getProductCardTags,
  };
};

export default useUiHelpers;
