/* istanbul ignore file */
export default function binarySearch<T>(
  array: T[] = [],
  target: T,
  compareFn: (a: T, b: T) => boolean,
  start = 0,
  end: number = array.length - 1,
): T | null {
  // no items found
  if (start > end) return null;

  // find mid
  const midpoint = Math.floor((start + end) / 2);

  // we gottem!
  if (array[midpoint] === target) return target;

  // mid is after target
  if (compareFn(array[midpoint], target))
    return binarySearch<T>(array, target, compareFn, start, midpoint - 1);
  // mid is before target
  else return binarySearch<T>(array, target, compareFn, midpoint + 1, end);
}
