import { Component, IArticleCard, RichTextEditorComponent } from '~/components/ArticleCard/types';
import { Block } from '~/components/CMS/RichTextDisplay/constants/block';

const WORD_COUNT_PER_MINUTE = 200;

const countWords = (str: string): number => {
  const arr = str.split(' ');

  return arr.filter((word) => word !== '').length;
};

export const calculateBlogReadTime = (article: IArticleCard) => {
  let textArray: string[] = [];
  textArray.push(article.content?.title ?? '');
  textArray.push(article.content?.category?.name ?? '');

  const richTextComponents = (article.content.content || []).filter(
    (item: Component | RichTextEditorComponent) => item.component === 'RichTextEditor',
  ) as RichTextEditorComponent[];

  richTextComponents.forEach((richTextComponent) => {
    richTextComponent.content.content.forEach((topLevelNode) => {
      if (
        topLevelNode.type === Block.HEADING ||
        topLevelNode.type === Block.PARAGRAPH ||
        topLevelNode.type === Block.CODE
      ) {
        const texts = topLevelNode.content?.map((node) => node.text);
        if (texts) textArray = [...textArray, ...texts];
      }
      if (topLevelNode.type === Block.QUOTE) {
        topLevelNode.content.forEach((node) => {
          const texts = node.content?.map((node) => node.text);
          if (texts) textArray = [...textArray, ...texts];
        });
      }
      if (topLevelNode.type === Block.OL_LIST || topLevelNode.type === Block.UL_LIST) {
        topLevelNode.content.forEach((listItemNode) => {
          listItemNode.content?.map((paragraphNode) => {
            const texts = paragraphNode.content?.map((node) => node.text);
            if (texts) textArray = [...textArray, ...texts];
          });
        });
      }
    });
  });

  const wordCount = countWords(textArray.join(' '));

  return Math.round(wordCount / WORD_COUNT_PER_MINUTE) || 1;
};

export default calculateBlogReadTime;
