/* istanbul ignore file */
import { computed, reactive } from '@nuxtjs/composition-api';

export interface UiNotification {
  key: string;
  message: string;
  type: 'danger' | 'success' | 'info';
  icon: string;
  payload?: unknown;
  persist?: boolean;
  id?: symbol;
  dismiss?: () => void;
  action?: { text: string; onClick: (...args: unknown[]) => void };
}

interface Notifications {
  notifications: Array<UiNotification>;
}

const state = reactive<Notifications>({
  notifications: [],
});
const maxVisibleNotifications = 1;

const useUiNotification = () => {
  const send = (notification: UiNotification) => {
    const id = Symbol(notification.key);

    const dismiss = () => {
      const index = state.notifications.findIndex((notification) => notification.id === id);

      if (index !== -1) state.notifications.splice(index, 1);
    };

    const newNotification = {
      ...notification,
      id,
      dismiss,
    };

    state.notifications.push(newNotification);
    if (state.notifications.length > maxVisibleNotifications) state.notifications.shift();
  };

  return {
    send,
    notifications: computed(() => state.notifications),
  };
};

export default useUiNotification;
