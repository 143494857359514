export const textNodesUnder = (el: Element) => {
  let node;
  const allNodes = [];
  const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null);
  while ((node = walk.nextNode())) allNodes.push(node);
  return allNodes;
};

// for debugging
export const drawRects = (rects: DOMRectList) => {
  for (let j = 0; j < rects.length; j++) {
    console.log('rects', rects[j]);
    const box = document.createElement('div');
    box.classList.add('box');
    box.style.top = `${rects[j].y}px`;
    box.style.left = `${rects[j].x}px`;
    box.style.width = `${rects[j].width}px`;
    box.style.height = `${rects[j].height}px`;
    box.style.position = 'absolute';
    box.style.border = '1px solid red';
    box.style.pointerEvents = 'none';
    document.body.appendChild(box);
  }
};
export const getTextContentLineCount = (el: Element, drawDebugBoxes = false) => {
  if (!el) return -1;
  const textNodes = textNodesUnder(el);
  const uniqueRectYVals = new Set();
  textNodes.forEach((node) => {
    const range = document.createRange();
    range.setStart(node, 0);
    range.setEnd(node, node?.textContent?.length ?? 0);
    const nodeRects = range.getClientRects();
    if (drawDebugBoxes) drawRects(nodeRects); // for debugging
    Array.from(nodeRects).forEach((rect) => uniqueRectYVals.add(rect.top));
    range.detach();
  });
  return uniqueRectYVals.size;
};
