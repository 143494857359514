import { render, staticRenderFns } from "./RecommendedProducts.vue?vue&type=template&id=53f9a548&scoped=true&"
import script from "./RecommendedProducts.vue?vue&type=script&lang=ts&"
export * from "./RecommendedProducts.vue?vue&type=script&lang=ts&"
import style0 from "./RecommendedProducts.vue?vue&type=style&index=0&id=53f9a548&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f9a548",
  null
  
)

export default component.exports