import { computed, useStore } from '@nuxtjs/composition-api';
import {
  IProductResult,
  productResultGetters,
} from '@unified-commerce/gpc-vue-storefront-search-io';
import { Product, productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

const useBrandLink = (product: Product | IProductResult = {} as any) => {
  const store = useStore() as any;
  const vendorBrandSlugs = computed(() => store.state.allVendorBrandSlugs);
  const isProduct = productGetters.isProduct(product as Product);

  const vendor = isProduct
    ? productGetters.getProductVendor(product as Product)
    : productResultGetters.getProductVendor(product as IProductResult);

  const brandLink = vendor ? vendorBrandSlugs.value[vendor] ?? `/c?vendor=${vendor}` : '';

  return brandLink;
};

export default useBrandLink;
