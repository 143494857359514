var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.images.length)?_c('UCCardSection',{staticClass:"image-carousel",attrs:{"title":_vm.title,"description":_vm.description,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: '50%',
    showSteppers: false,
    cardSpacing: '8px',
    rootStyle: { margin: '0px' },
  },"tablet":{
    cardFlexBasis: '25%',
    showSteppers: true,
    cardSpacing: '16px',
  },"desktop":{
    cardFlexBasis: '20%',
    showSteppers: true,
    cardSpacing: '24px',
  }}},_vm._l((_vm.images),function(image,i){return _c('nuxt-img',{key:i,staticClass:"image-carousel__image",attrs:{"provider":"storyblok","src":_vm.isMobile ? image.imageMobileSrc.filename : image.imageSrc.filename,"alt":_vm.isMobile ? image.imageMobileSrc.alt : image.imageSrc.alt}})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }