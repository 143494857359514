import { SET_ALL_SHOPIFY_CATEGORIES, SET_ALL_VENDOR_BRAND_SLUGS } from './constants';

export const state = () => ({
  allShopifyCategories: [],
  allVendorBrandSlugs: {},
});

export const mutations = {
  [SET_ALL_SHOPIFY_CATEGORIES](state, categories) {
    state.allShopifyCategories = categories;
  },
  [SET_ALL_VENDOR_BRAND_SLUGS](state, vendorBrandSlugs) {
    state.allVendorBrandSlugs = vendorBrandSlugs;
  },
};

export const actions = {
  async nuxtServerInit() {
    // Do something on server initialised
  },
};
