import { integrationPlugin } from '@unified-commerce/vue-storefront-integration-plugin';

const moduleOptions = JSON.parse('{}');

export default integrationPlugin(({ app, integration }) => {
  integration.configure('googlePlaces', {
    ...moduleOptions,
    app,
  });
});
