import { Collection, ISearchParams } from '@unified-commerce/gpc-vue-storefront-search-io';

const getFitmentByKtypeParams = (askus: string[], ktypNr: string): ISearchParams => {
  const escapedASKUs = askus.map((string) => `"${string}"`);
  return {
    collection: Collection.FITMENT,
    pipeline: {},
    filter: `asku IN (${escapedASKUs.join(',')}) AND ktypnr IN (${ktypNr}) `,
  };
};

export default getFitmentByKtypeParams;
