import qs from 'qs';

export const FACET_QUERY_PARAM_NAME = 'facet';

export interface IFacetFilter {
  name: string;
  kind: string;
  checkboxOptions: Array<ICheckboxOption>;
}

export interface ICheckboxOption {
  label: string;
  name: string;
  value: string;
  selected: boolean;
  facet: string;
  trailingNumber: number;
  query: string;
}

// Function to add facet to url
const getFilterLink = (filterAdded: ICheckboxOption, query: any) => {
  const FACET_NAME_VALUE = `${filterAdded.facet}:${filterAdded.value}`;

  // if query has a facet
  if (FACET_QUERY_PARAM_NAME in query) {
    const facetQueries = [];

    // Add current facets in query into array
    if (Array.isArray(query[FACET_QUERY_PARAM_NAME])) {
      facetQueries.push(query[FACET_QUERY_PARAM_NAME]);
    } else {
      const facetQuery: string[] = [];
      facetQuery.push(query[FACET_QUERY_PARAM_NAME]);
      facetQueries.push(facetQuery);
    }

    let isNewFacet = true;
    let indexToRemove = null;
    let isToBeRemoved = false;
    const newFacetQueries = facetQueries[0].map((facet: string, index: number) => {
      if (facet.includes(filterAdded.value)) {
        isNewFacet = false;
        // remove value from existing facet
        if (facet.includes(',')) {
          facet = facet.replace(`,${filterAdded.value}`, '');
          facet = facet.replace(`${filterAdded.value},`, '');
          // remove existing facet
        } else {
          indexToRemove = index;
          isToBeRemoved = true;
        }
        return facet;
      }
      // Add new value into existing facet
      if (facet.includes(filterAdded.facet) && !facet.includes(filterAdded.value)) {
        isNewFacet = false;
        facet = `${facet},${filterAdded.value}`;
        return facet;
      }
      return facet;
    });

    if (isToBeRemoved) {
      newFacetQueries.splice(indexToRemove, 1);
    }

    if (isNewFacet) {
      newFacetQueries.push(FACET_NAME_VALUE);
    }

    return qs.stringify(
      { ...query, [FACET_QUERY_PARAM_NAME]: newFacetQueries },
      { addQueryPrefix: true, arrayFormat: 'repeat' },
    );

    // if query does not have a facet
  } else {
    return qs.stringify(
      { ...query, [FACET_QUERY_PARAM_NAME]: FACET_NAME_VALUE },
      { addQueryPrefix: true },
    );
  }
};

export default getFilterLink;
