import { UiNotification } from '.';

export const PRODUCT_ADDED_TO_CART_NOTIFICATION_KEY = 'product_added';
export const getProductAddedToCartNotification = ({
  name,
  compareToPrice,
  price,
  checkoutURL,
  quantity = 1,
}: {
  name: string;
  compareToPrice?: number | null;
  price: number;
  checkoutURL: string;
  quantity?: number;
}): UiNotification => ({
  key: PRODUCT_ADDED_TO_CART_NOTIFICATION_KEY,
  type: 'success',
  message: `${name} has been successfully added to your cart!`,
  icon: 'check',
  payload: {
    compareToPrice,
    price,
    checkoutURL,
    productTitle: name,
    quantity,
  },
});

export const PRODUCT_FAILED_TO_ADD_TO_CART_NOTIFICATION_KEY = 'product_failed_to_add_to_cart';
export const getProductFailedToAddCartNotification = ({
  name,
}: {
  name: string;
}): UiNotification => ({
  key: PRODUCT_FAILED_TO_ADD_TO_CART_NOTIFICATION_KEY,
  type: 'danger',
  message: `${name} could not be added to your cart.`,
  icon: '',
});
